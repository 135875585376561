<template>
  <nav class="header-navbar navbar-expand-lg navbar navbar-fixed align-items-center navbar-shadow navbar-brand-center" data-nav="brand-center">
    <div class="navbar-header d-xl-block d-none">
      <!-- MENU LOGO -->
      <ul class="nav navbar-nav">
        <li class="nav-item">
          <router-link class="navbar-brand d-flex flex-column align-items-center" to="/">
            <img height="40" src="img/logo-clube-shield.png"/>
          </router-link>
        </li>
      </ul>
      <!-- FIM MENU LOGO -->
    </div>
    <div class="navbar-container d-flex content">
      <div class="bookmark-wrapper d-flex align-items-center collapse navbar-collapse" id="navbarSupportedContent">
        <!-- MENU ESQUERDO -->
        <ul class="nav navbar-nav bookmark-icons">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="dropdown-tickets" title="Tickets" href="javascript:void(0);" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <div class="user-nav d-sm-flex d-none">
                <file-icon size="1.5x" class="ficon" title="Tickets" ></file-icon>
              </div>
            </a>
            <div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdown-tickets">
              <router-link v-if="canAccess('menu_tickets_simples')" class="dropdown-item" to="/ticket">
                <file-icon size="1.5x" class="ficon"></file-icon> Novo ticket simples
              </router-link>
              <router-link v-if="canAccess('menu_tickets_combo')" class="dropdown-item" to="/ticket/combo">
                <share-2-icon size="1.5x" class="ficon"></share-2-icon> Novo ticket combo
              </router-link>
<!--              <router-link v-if="canAccess('menu_tickets_adaptacao')" class="dropdown-item" to="/ticket/adaptacao">-->
<!--                <file-plus-icon size="1.5x" class="ficon"></file-plus-icon> Novo ticket de adaptação-->
<!--              </router-link>-->
<!--              <router-link v-if="canAccess('menu_tickets_replicacao')" class="dropdown-item" to="/ticket/replicacao">-->
<!--                <copy-icon size="1.5x" class="ficon"></copy-icon> Novo ticket de replicação-->
<!--              </router-link>-->
              <div class="dropdown-divider"></div>
              <router-link v-if="canAccess('menu_tipo_fases')" class="dropdown-item" to="/tipos/fases">
                <settings-icon size="1.5x" class="ficon"></settings-icon> Tipos de Fases
              </router-link>
              <router-link v-if="canAccess('menu_tipo_servicos')" class="dropdown-item" to="/tipos/jobs">
                <settings-icon size="1.5x" class="ficon"></settings-icon> Tipos de Serviços
              </router-link>
            </div>
          </li>
          <!--          <li class="nav-item dropdown">-->
          <!--            <a class="nav-link dropdown-toggle" id="dropdown-budget" href="javascript:void(0);" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
          <!--              <div class="user-nav d-sm-flex d-none">-->
          <!--                <dollar-sign-icon size="1.5x" class="ficon" title="Tickets" ></dollar-sign-icon>-->
          <!--              </div>-->
          <!--            </a>-->
          <!--            <div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdown-budget">-->
          <!--              <router-link v-if="canAccess('menu_tickets_simples')" class="dropdown-item" to="/tickets/orcamentos/lista">-->
          <!--                <list-icon size="1.5x" class="ficon"></list-icon> Lista de orçamentos-->
          <!--              </router-link>-->
          <!--              <router-link v-if="canAccess('menu_tickets_combo')" class="dropdown-item" to="/ticket/orcamento">-->
          <!--                <file-icon size="1.5x" class="ficon"></file-icon> Novo ticket orçamento-->
          <!--              </router-link>-->
          <!--              <div class="dropdown-divider"></div>-->
          <!--              <router-link v-if="canAccess('menu_tipo_fornecedores')" class="dropdown-item" to="/tipos/fornecedores">-->
          <!--                <settings-icon size="1.5x" class="ficon"></settings-icon> Tipos de Fornecedores-->
          <!--              </router-link>-->
          <!--              <router-link v-if="canAccess('menu_fornecedores')" class="dropdown-item" to="/fornecedores">-->
          <!--                <settings-icon size="1.5x" class="ficon"></settings-icon> Cadastro de fornecedor-->
          <!--              </router-link>-->
          <!--            </div>-->
          <!--          </li>-->
          <li v-if="canAccess('menu_tickets_lista')" class="nav-item d-none d-lg-block">
            <router-link class="nav-link" title="Lista de tickets" to="/tickets">
              <list-icon size="1.5x" class="ficon"></list-icon>
            </router-link>
          </li>
          <li v-if="canAccess('menu_tickets_pauta')" class="nav-item d-none d-lg-block">
            <router-link class="nav-link" title="Pauta" to="/pauta">
              <activity-icon size="1.5x" class="ficon"></activity-icon>
            </router-link>
          </li>
          <li v-if="canAccess('menu_cliente_ficha')" class="nav-item d-none d-lg-block">
            <router-link class="nav-link" title="Ficha de Cliente" to="/fichas">
              <database-icon size="1.5x" class="ficon"></database-icon>
            </router-link>
          </li>
          <li v-if="canAccess('menu_relatorios')" class="nav-item d-none d-lg-block dropdown">
            <a id="navbarDropdown" aria-expanded="false" aria-haspopup="true" class="nav-link" data-toggle="dropdown" title="Relatórios">
              <pie-chart-icon size="1.5x" class="ficon"></pie-chart-icon>
            </a>
            <ul class="dropdown-menu">
              <li class="nav-item d-none d-lg-block" v-if="canAccess('menu_relatorio_dashboard')">
                <router-link class="dropdown-item" to="/dashboard">
                  <pie-chart-icon size="1.5x" class="ficon"></pie-chart-icon>
                  <span data-i18n="Typography">Dashboard</span>
                </router-link>
              </li>
              <li class="nav-item d-none d-lg-block" v-if="canAccess('menu_relatorio_jobs')">
                <router-link class="dropdown-item" to="/relatorio/jobs">
                  <type-icon size="1.5x" class="ficon"></type-icon>
                  <span data-i18n="Typography">Tempos e Prazos 2023</span>
                </router-link>
              </li>
              <li class="nav-item d-none d-lg-block" v-if="canAccess('menu_relatorio_jobs')">
                <router-link class="dropdown-item" to="/relatorio/jobshoras">
                  <type-icon size="1.5x" class="ficon"></type-icon>
                  <span data-i18n="Typography">Custo por Serviço 2023</span>
                </router-link>
              </li>
              <hr>
              <li class="nav-item d-none d-lg-block" v-if="canAccess('menu_relatorio_horas')">
                <router-link class="dropdown-item" to="/relatorio/horas/trabalhadas">
                  <type-icon size="1.5x" class="ficon"></type-icon>
                  <span data-i18n="Typography">Relatório de Contrato: Detalhado 2023</span>
                </router-link>
              </li>
              <li class="nav-item d-none d-lg-block" v-if="canAccess('menu_relatorio_horas')">
                <router-link class="dropdown-item" to="/relatorio/horas/trabalhadasexport">
                  <type-icon size="1.5x" class="ficon"></type-icon>
                  <span data-i18n="Typography">Relatório de Contrato: Consolidado 2023</span>
                </router-link>
              </li>
              <li class="nav-item d-none d-lg-block" v-if="canAccess('menu_relatorio_horas_gerencial')">
                <router-link class="dropdown-item" to="/relatorio/horas/gerencial">
                  <type-icon size="1.5x" class="ficon"></type-icon>
                  <span data-i18n="Typography">Relatório de Contrato: Gerencial 2023</span>
                </router-link>
              </li>
              <li class="nav-item d-none d-lg-block" v-if="canAccess('menu_relatorio_horas_cliente')">
                <router-link class="dropdown-item" to="/relatorio/horas/gerencialgeral">
                  <type-icon size="1.5x" class="ficon"></type-icon>
                  <span data-i18n="Typography">Status de Contrato de Clientes 2023</span>
                </router-link>
              </li>
              <hr>
              <li class="nav-item d-none d-lg-block" v-if="canAccess('menu_relatorios_produtividade_pessoa')">
                <router-link class="dropdown-item" to="/relatorio/relprodutividadepessoa">
                  <type-icon size="1.5x" class="ficon"></type-icon>
                  <span data-i18n="Typography">Produtividade por Usuário 2023</span>
                </router-link>
              </li>
              <li class="nav-item d-none d-lg-block" v-if="canAccess('menu_relatorios_produtividade_setor')">
                <router-link class="dropdown-item" to="/relatorio/relprodutividadesetor">
                  <type-icon size="1.5x" class="ficon"></type-icon>
                  <span data-i18n="Typography">Produtividade por Setor 2023</span>
                </router-link>
              </li>
              <li class="nav-item d-none d-lg-block" v-if="canAccess('menu_relatorios_retrabalho')">
                <router-link class="dropdown-item" to="/relatorio/retrabalho">
                  <type-icon size="1.5x" class="ficon"></type-icon>
                  <span data-i18n="Typography">Retrabalho por Cliente 2023</span>
                </router-link>
              </li>
              <hr>
              <li class="nav-item d-none d-lg-block" v-if="canAccess('menu_relatorios_cafeteria_pedidos')">
                <router-link class="dropdown-item" to="/cafeteria/relatorios/pedidos">
                  <type-icon size="1.5x" class="ficon"></type-icon>
                  <span data-i18n="Typography">Cafeteria: Pedidos 2023</span>
                </router-link>
              </li>
              <li class="nav-item d-none d-lg-block" v-if="canAccess('menu_relatorios_cafeteria_itens_pedidos')">
                <router-link class="dropdown-item" to="/cafeteria/relatorios/itens">
                  <type-icon size="1.5x" class="ficon"></type-icon>
                  <span data-i18n="Typography">Cafeteria: Ítens Pedidos 2023</span>
                </router-link>
              </li>
            </ul>
          </li>
          <!--          <li class="nav-item dropdown" style="margin-top: 2px;">-->
          <!--            <a class="nav-link dropdown-toggle" id="dropdown-crm" title="CRM" href="javascript:void(0);" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
          <!--              <div class="user-nav d-sm-flex d-none">-->
          <!--                <crosshair-icon size="1.5x" class="ficon" title="CRM" ></crosshair-icon>-->
          <!--              </div>-->
          <!--            </a>-->
          <!--            <div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdown-crm">-->
          <!--              <router-link class="dropdown-item" to="/crm/oportunidades">-->
          <!--                <file-icon size="1.5x" class="ficon"></file-icon> Oportunidades-->
          <!--              </router-link>-->
          <!--              &lt;!&ndash;              <router-link v-if="canAccess('menu_tickets_combo')" class="dropdown-item" to="/ticket/combo">&ndash;&gt;-->
          <!--              &lt;!&ndash;                <share-2-icon size="1.5x" class="ficon"></share-2-icon> Novo ticket combo&ndash;&gt;-->
          <!--              &lt;!&ndash;              </router-link>&ndash;&gt;-->
          <!--              &lt;!&ndash;              <router-link v-if="canAccess('menu_tickets_adaptacao')" class="dropdown-item" to="/ticket/adaptacao">&ndash;&gt;-->
          <!--              &lt;!&ndash;                <file-plus-icon size="1.5x" class="ficon"></file-plus-icon> Novo ticket de adaptação&ndash;&gt;-->
          <!--              &lt;!&ndash;              </router-link>&ndash;&gt;-->
          <!--              &lt;!&ndash;              <router-link v-if="canAccess('menu_tickets_replicacao')" class="dropdown-item" to="/ticket/replicacao">&ndash;&gt;-->
          <!--              &lt;!&ndash;                <copy-icon size="1.5x" class="ficon"></copy-icon> Novo ticket de replicação&ndash;&gt;-->
          <!--              &lt;!&ndash;              </router-link>&ndash;&gt;-->
          <!--              <div class="dropdown-divider"></div>-->
          <!--              <router-link class="dropdown-item" to="/crm/origemoportunidades">-->
          <!--                <settings-icon size="1.5x" class="ficon"></settings-icon> Cadastro de Origem de oportunidade-->
          <!--              </router-link>-->
          <!--              <router-link class="dropdown-item" to="/crm/motivos">-->
          <!--                <settings-icon size="1.5x" class="ficon"></settings-icon> Cadastro de Motivos-->
          <!--              </router-link>-->
          <!--              <router-link class="dropdown-item" to="/crm/lead">-->
          <!--                <settings-icon size="1.5x" class="ficon"></settings-icon> Cadastro de Leads-->
          <!--              </router-link>-->
          <!--              <router-link class="dropdown-item" to="/crm/funil">-->
          <!--                <settings-icon size="1.5x" class="ficon"></settings-icon> Cadastro de Funil-->
          <!--              </router-link>-->
          <!--            </div>-->
          <!--          </li>-->
        </ul>

      </div>
      <!-- FIM MENU ESQUERDO -->
      <!-- MENU DIREITO -->
      <ul class="nav navbar-nav align-items-center ml-auto">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="dropdown-cafeteria" href="javascript:void(0);" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div class="user-nav d-sm-flex d-none">
              <coffee-icon size="1.5x" class="ficon"></coffee-icon>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdown-cafeteria">
            <router-link v-if="canAccess('menu_cafeteria')" class="dropdown-item" title="Cafeteria" to="/cafeteria/pedido">
              <plus-icon size="1.5x" class="ficon"></plus-icon> Novo pedido
            </router-link>
            <div class="dropdown-divider"></div>
            <router-link v-if="canAccess('menu_cafeteria_produtos')" class="dropdown-item" to="/cafeteria/categorias">
              <settings-icon size="1.5x" class="ficon"></settings-icon>
              Categoria de produtos
            </router-link>
            <router-link v-if="canAccess('menu_cafeteria_produtos')" class="dropdown-item" to="/cafeteria/produtos">
              <settings-icon size="1.5x" class="ficon"></settings-icon>
              Produtos da cafeteria
            </router-link>
          </div>
        </li>
        <li v-if="canAccess('menu_cliente_usuarios')" class="nav-item d-none d-lg-block">
          <router-link class="nav-link" title="Usuários de Clientes" to="/cliente/usuarios">
            <user-check-icon size="1.5x" class="ficon"></user-check-icon>
          </router-link>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="dropdown-agenda" href="javascript:void(0);" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div class="user-nav d-sm-flex d-none">
              <calendar-icon size="1.5x" class="ficon"></calendar-icon>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdown-agenda">
            <router-link class="dropdown-item" title="Agenda" to="/calendario">
              <calendar-icon size="1.5x" class="ficon"></calendar-icon> Ver agenda
            </router-link>
            <div class="dropdown-divider"></div>
            <router-link v-if="canAccess('menu_feriados')" class="dropdown-item" to="/lista/feriados">
              <settings-icon size="1.5x" class="ficon"></settings-icon> Feriados
            </router-link>
          </div>
        </li>
        <li v-if="canAccess('menu_tickets_administrativo')" class="nav-item d-none d-lg-block">
          <router-link class="nav-link" title="Tickets Administrativos" to="/adm/tickets">
            <clipboard-icon size="1.5x" class="ficon"></clipboard-icon>
          </router-link>
        </li>
        <li v-if="canAccess('menu_tickets_sistema')" class="nav-item d-none d-lg-block">
          <router-link class="nav-link" title="Tickets de Sistema" to="/sys/tickets">
            <terminal-icon size="1.5x" class="ficon"></terminal-icon>
          </router-link>
        </li>
        <li v-if="canAccess('menu_tickets_qualidade')" class="nav-item d-none d-lg-block">
          <router-link class="nav-link" title="Tickets de Qualidade" to="/qua/tickets">
            <star-icon size="1.5x" class="ficon"></star-icon>
          </router-link>
        </li>
        <li class="nav-item d-none d-lg-block">
          <a class="nav-link" href="//clubedemidia.minhaofertaon.com.br/" target="_blank" title="Benefícios do Clube">
            <shopping-cart-icon size="1.5x" class="ficon text-warning"></shopping-cart-icon>
          </a>
        </li>
        <!-- <li class="nav-item d-none d-lg-block">
                    <a class="nav-link nav-link-style">
                        <moon-icon size="1.5x" class="ficon"></moon-icon>
                    </a>
                </li> -->
        <li class="nav-item dropdown dropdown-notification mr-25">
          <a class="nav-link" href="javascript:void(0);" data-toggle="dropdown">
            <bell-icon size="1.5x" class="ficon"></bell-icon>
            <span v-if="notifications_unread_count >= 1" class="badge badge-pill badge-danger badge-up">
              {{ notifications_unread_count }}
            </span>
          </a>
          <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right">
            <li class="dropdown-menu-header">
              <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 mr-auto">Notificações</h4>
                <div class="badge badge-pill badge-light-primary">{{ notifications_unread_count }}</div>
              </div>
            </li>

            <li class="scrollable-container media-list">

              <div v-if="notifications.length" class="list-group hidden-box">
                <div
                  v-for="(item, idx) in notifications"
                  :key="item._id"
                  class="d-flex"
                >
                  <div v-if="notifications.length" class="media d-flex align-items-start position-relative">
                    <i class="la la-trash link position-absolute" style="right: 2%;bottom: 10%;" @click="removeNotification(notifications, idx)"></i>
                    <router-link :to="'/aviso/' + item._id" class="text-nowrap d-flex justify-content-between flex-grow-1">
                      <span class="font-weight-bolder">{{ item.brief }}</span><br>
                      <i class="text-muted">{{ formatElapsed(item.created_at) }}</i>
                    </router-link>
                  </div>
                  <div v-else class="p-2 text-center">
                    <small class="notification-text">Nenhuma notificação</small>
                  </div>
                </div>
              </div>
            </li>
            <li class="dropdown-menu-footer"><a class="btn btn-primary btn-block" href="javascript:void(0)" @click="removeAllNotifications()">Limpar notificações</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown dropdown-notification mr-25">
          <a class="nav-link" href="javascript:void(0);" data-toggle="dropdown">
            <at-sign-icon size="1.5x" class="ficon"></at-sign-icon>
            <span v-if="mentions_unread_count >= 1" class="badge badge-pill badge-primary badge-up">
              {{ mentions_unread_count }}
            </span>
          </a>
          <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right">
            <li class="dropdown-menu-header">
              <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 mr-auto">Menções</h4>
                <div class="badge badge-pill badge-light-primary">{{ mentions_unread_count }}</div>
              </div>
            </li>
            <li class="scrollable-container media-list">

              <div v-if="mentions.length" class="list-group hidden-box">
                <div
                  v-for="(mention, idx) in mentions"
                  :key="mention._id"
                  class="d-flex"
                >
                  <div class="media d-flex align-items-start position-relative">
                    <i class="la la-trash link position-absolute" style="right: 2%;bottom: 11%;" @click="removeMention(mention, idx)"></i>
                    <div class="media-left">
                      <div class="avatar bg-light">
                        <div class="avatar-content"><img height="40" width="40" src="img/clubedemidia-world-escudo.png"></div>
                      </div>
                    </div>
                    <div class="media-body">
                      <p class="media-heading">
                        <span class="font-weight-bolder" v-html="mention.content"/>
                      </p>
                      <small class="notification-text">{{ formatElapsed(mention.created_at) }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="dropdown-menu-footer"><a class="btn btn-primary btn-block" href="javascript:void(0)" @click="removeAllMentions()">Limpar menções</a></li>
          </ul>
        </li>
        <li v-if="canAccess('menu_configuracao')" class="nav-item dropdown">
          <a id="navbarDropdown" aria-expanded="false" aria-haspopup="true" class="nav-link" data-toggle="dropdown" href="#" role="button" title="Configurações">
            <settings-icon size="1.5x" class="ficon"></settings-icon>
          </a>
          <div aria-labelledby="navbarDropdown" class="dropdown-menu dropdown-menu-right">
            <router-link v-if="canAccess('menu_empresa_usuario')" class="dropdown-item" to="/usuarios">Usuários da Empresa</router-link>
            <router-link v-if="canAccess('configuracoes_cliente')" class="dropdown-item" to="/clientes">Clientes</router-link>

            <router-link v-if="canAccess('menu_empresas')" class="dropdown-item" to="/empresas">Empresas</router-link>
            <router-link v-if="canAccess('menu_empresa_equipes')" class="dropdown-item" to="/equipes">Equipes</router-link>
            <div class="dropdown-divider"></div>
            <router-link v-if="canAccess('menu_avisos')" class="dropdown-item" to="/lista/avisos">Avisos</router-link>
            <div class="dropdown-divider"></div>
            <router-link v-if="canAccess('menu_wiki_capitulos')" class="dropdown-item" to="/wiki/capitulos">Capítulos da Wiki</router-link>
            <router-link v-if="canAccess('menu_reuniao_locais')" class="dropdown-item" to="/ticket/reuniao/locais">Locais de Reunião</router-link>
            <router-link v-if="canAccess('menu_empresa_setores')" class="dropdown-item" to="/setores">Setores</router-link>
            <router-link v-if="canAccess('menu_tipo_contratos')" class="dropdown-item" to="/tipos/contratos">Tipos de Contratos</router-link>
            <router-link v-if="canAccess('menu_tipo_servicos_sistema')" class="dropdown-item" to="/sys/tipos">Tipos de Serviços do Sistema</router-link>
            <router-link v-if="canAccess('menu_tipo_servicos_administrativo')" class="dropdown-item" to="/adm/tipos">Tipos de Serviços Administrativos</router-link>
            <router-link v-if="canAccess('menu_tipo_servicos_qualidade')" class="dropdown-item" to="/qua/tipos">Tipos de Serviços Qualidade</router-link>
            <div class="dropdown-divider"></div>
            <router-link v-if="canAccess('menu_logs')" class="dropdown-item" to="/logs">Logs</router-link>
            <router-link v-if="canAccess('menu_permissoes')" class="dropdown-item" to="/permissoes">Permissões</router-link>
            <router-link v-if="canAccess('menu_configuracao')" class="dropdown-item" to="/configuracoes">Configurações</router-link>
            <router-link v-if="canAccess('menu_trocar_usuario_equipe')" class="dropdown-item" to="/trocar_usuario_equipe">Trocar usuário de fase</router-link>
            <router-link v-if="canAccess('menu_adicionar_pessoa_fase')" class="dropdown-item" to="/adicionar_pessoa_fase">Adicionar pessoa a fase</router-link>
            <router-link class="dropdown-item" to="/agrupador_relatorios">Agrupador de relatórios</router-link>
            <div class="dropdown-divider"></div>
            <div class="dropdown-item disabled"><small>Ver: 202111051141</small></div>
          </div>
        </li>

        <li class="nav-item dropdown dropdown-user">
          <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" href="javascript:void(0);" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div class="user-nav d-sm-flex d-none"><span class="user-name font-weight-bolder">{{ firstName(user_name) }}</span><span class="user-status">{{ role_name }}</span></div><span class="avatar"><img class="round" :src="checkPhoto(photo)" alt="avatar" height="40" width="40"><span class="avatar-status-online"></span></span>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown-user">
            <router-link class="dropdown-item" to="/profile">
              <user-icon size="1.5x" class="mr-50"></user-icon> Meus dados
            </router-link>
            <router-link class="dropdown-item" to="/cafeteria/meuspedidos">
              <package-icon size="1.5x" class="mr-50"></package-icon> Meus pedidos
            </router-link>
            <router-link class="dropdown-item" to="/logout">
              <power-icon size="1.5x" class="mr-50"></power-icon> Sair
            </router-link>
          </div>
        </li>
      </ul>
      <!-- FIM MENU DIREITO -->
    </div>
  </nav>
</template>

<script>
import Vue from 'vue'
import { H } from 'hennig-common'
import info from '../../../../info.json'

import { FileIcon, CopyIcon, Share2Icon, FilePlusIcon, ListIcon, ActivityIcon, DatabaseIcon, PieChartIcon, TypeIcon, CoffeeIcon, UserCheckIcon, CalendarIcon,
  ClipboardIcon, TerminalIcon, StarIcon, ShoppingCartIcon, BellIcon, AtSignIcon, SettingsIcon, PowerIcon, UserIcon, MoonIcon, PackageIcon, PlusIcon, DollarSignIcon,
  CrosshairIcon
} from 'vue-feather-icons'

export default {
  name: 'Logged',
  components: {
    FileIcon,
    Share2Icon,
    CopyIcon,
    FilePlusIcon,
    ListIcon,
    ActivityIcon,
    DatabaseIcon,
    PieChartIcon,
    TypeIcon,
    CoffeeIcon,
    UserCheckIcon,
    CalendarIcon,
    ClipboardIcon,
    TerminalIcon,
    StarIcon,
    ShoppingCartIcon,
    BellIcon,
    AtSignIcon,
    SettingsIcon,
    PowerIcon,
    UserIcon,
    PackageIcon,
    PlusIcon,
    MoonIcon,
    DollarSignIcon,
    CrosshairIcon
  },
  data () {
    return {
      version: info.version,
      mentions_unread_count: 0,
      mentions: [],
      notifications_unread_count: 0,
      notifications: [],
      photo: '',
      role_name: '',
      ...window.auth
    }
  },
  mounted () {
    this.getMessages()
    this.getMentions()
    this.initEcho()
  },
  methods: {
    getMessages () {
      H.rpc('Message', 'getMy', [{'new': true}], (r) => {
        if (r) {
          this.notifications = []

          for (const item of r.data) {
            if (item.type === 'L') {
              if (window.sessionStorage.getItem(item._id)) {
                continue
              }
              window.sessionStorage.setItem(item._id, true)
              $(`<div class="modal lightbox" tabindex="-1">
                                <div class="modal-dialog modal-dialog-centered modal-xl">
                                    <div class="modal-content">
                                        <div class="content"></div>
                                    </div>
                                </div>
                            </div>`
              )
                .find('.content')
                .html(item.content)
                .end()
                .on('hidden.bs.modal', function () {
                  $(this).remove()
                })
                .appendTo($('body'))
                .modal('show')
              continue
            }

            this.notifications.push(item)
          }

          this.notifications_unread_count = r.total
        }
      })
    },
    getMentions () {
      H.rpc('Mention', 'getMy', [{'new': true}], (r) => {
        if (r) {
          this.mentions = []

          for (const item of r.data) {
            if (item.type === 'L') {
              if (window.sessionStorage.getItem(item._id)) {
                continue
              }
              window.sessionStorage.setItem(item._id, true)
              $(`<div class="modal lightbox" tabindex="-1">
                                <div class="modal-dialog modal-dialog-centered modal-xl">
                                    <div class="modal-content bg-white">
                                        <div class="content"></div>
                                    </div>
                                </div>
                            </div>`
              )
                .find('.content')
                .html(item.content)
                .end()
                .on('hidden.bs.modal', function () {
                  $(this).remove()
                })
                .appendTo($('body'))
                .modal('show')
              continue
            }

            this.mentions.push(item)
          }

          this.mentions_unread_count = r.total
        }
      })
    },
    checkPhoto (photo) {
      if(photo === null) {
        photo = 'img/clubedemidia-world-escudo.png'

        return photo
      }
      return photo
    },
    firstName (user_name) {
      user_name = user_name.split(' ')
      return user_name[0]
    },
    initEcho () {
      if (!window.Echo || !window.auth.user_id) {
        setTimeout(this.initEcho, 5000)
        return
      }

      Echo.channel('Public')
        .listen('Deployed', function () {
          showInfo('Sistema foi atualizado')
        })

      Echo.private('User.' + window.auth.user_id)
        .listen('Mention', data => {
          this.mentions_unread_count++
          this.mentions.push(data)
        })
        .listen('Message', data => {
          this.notifications_unread_count++
          this.notifications.push(data)
        })
    },
    removeMention (item, idx) {
      H.rpc('Mention', 'setRead', [item._id], (r) => {
        if (!r) {return}
        this.mentions_unread_count--
        this.mentions.splice(idx, 1)
      })
    },
    removeAllMentions () {
      let index = []
      for(index in this.mentions) {
        H.rpc('Mention', 'setRead', [this.mentions[index]._id], (r) => {
          if (!r) {return}
          this.mentions_unread_count--
          this.mentions = []
        })
      }
      this.getMentions()
    },
    removeNotification (item, idx) {
      H.rpc('Message', 'setRead', [item._id], (r) => {
        if (!r) {return}
        this.notifications.splice(idx, 1)
        this.notifications_unread_count--
      })
    },
    removeAllNotifications () {
      let index = []
      for(index in this.notifications) {
        H.rpc('Message', 'setRead', [this.notifications[index]._id], (r) => {
          if (!r) {return}
          this.notifications_unread_count--
          this.notifications = []
        })
      }
      this.getMessages()
    },
  }
}
</script>

<style lang="scss" scoped>
.hidden-box {
    max-height: 300px !important;
    overflow-x: hidden !important;;
    overflow-y: scroll !important;;
}
</style>
